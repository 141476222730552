<div>
    <div *ngIf="error" class="alert alert-danger" role="alert">
        Could not load data: {{error}}.
    </div>
    <div class="d-flex justify-content-center">
        <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="showNavigationArrows"
            [interval]="interval" *ngIf="cards" class="maxw45">
            <ng-template ngbSlide *ngFor="let card of cards">
                <div class="card m-3">
                    <h4 class="card-header">
                      {{titlePrefix}}  {{card.title}}
                    </h4>
                    <div class="card-body">
                        <h5>Front</h5>
                        <img class="card-img-top" src="/assets/preview/{{card.frontPngName}}">
                        <h5>Back</h5>
                        <img class="card-img-top" src="/assets/preview/{{card.backPngName}}">
                        <p class="card-text text-center">
                            {{card.comment}}
                        </p>
                        <p class="card-text text-center">
                            <audio controls src="/assets/preview/{{card.backMp3Name}}" (play)="onPlayerPlay()"
                            (pause)="onPlayerPause()" (ended)="onPlayerEnded()"></audio>
                        </p>
                        <p class="card-text">Fields available for sorting and filtering:</p>
                        <ul>
                            <li>Title: {{card.title}}</li>
                            <li>Comment: {{card.comment}}</li>
                            <li>FretNumber: {{card.fretNumber}}</li>
                            <li>StringNumber: {{card.stringNumber}}</li>
                            <li>ScaleName: {{card.scaleName}}</li>
                            <li>ScaleTypeName: {{card.scaleTypeName}}</li>
                            <li>ScaleRootName: {{card.scaleRootName}}</li>
                            <li>ChordName: {{card.chordName}}</li>
                            <li>ChordTypeName: {{card.chordTypeName}}</li>
                            <li>ChordRootName: {{card.chordRootName}}</li>
                            <li>SystemName: {{card.systemName}}</li>
                        </ul>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>
