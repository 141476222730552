<div class="container">

    <!--
    <div class="alert alert-light" role="alert">
        German <a routerLink="/dsgvo" class="alert-link">Data Protection</a> information.
    </div>
    -->
    <div class="alert alert-light mt-3" role="alert">
        <a target="_blank" href="/assets/dsgvo.html" class="text-reset">Datenschutzerklärung (DE)</a> 
        &bull;
        <a target="_blank" href="/assets/impressum.html" class="text-reset">Impressum (DE)</a> 
        &bull;
        <a target="_blank" href="/assets/privacy.html" class="text-reset">Privacy Statement (EN)</a> 
        &bull;
        <a target="_blank" href="/assets/disclaimer.html" class="text-reset">Disclaimer (EN)</a> 
        &bull;
        <a target="_blank" href="/assets/termsconditions.html" class="text-reset">Terms and Conditions (EN)</a> 
    </div>

</div>