<div class="container">
    <div class="jumbotron bg-light mt-3 pt-4 pb-3 mb-0">
        <h2 class="heading">Practice Chords</h2>
        <hr>
        <h4>Overview</h4>
        <p>
            Many musicians practice scales and scale patterns to get more fluent with playing melodies and solos.
            This is an equivalent technical exercise for chords: you practice all chord types in all keys systematically.
        </p>
        <p>
          There are 3 sets of decks:
        </p>
        <ul>
            <li><strong>Chords</strong> - jam tracks for triads and common jazz chords.
              Every exercise focusses either on a certain chord in a certain key, or a common chord progression like II-V-I or Blues.
              You can use this deck to practice both, comping and soloing
              (<a href="/assets/preview/JamCGuitarDeck.html" target="_blank">Preview Deck here</a>)</li>
            <li><strong>Triads</strong> - Minor, Major, Augmented, Diminished and Sus4 Triads (<a href="/assets/preview/TriadsJamCGuitarDeck.html" target="_blank">Preview Deck here</a>)
              <br>The Guitar-Version of this asks you to play triads on High and Low string set. I'ts up to you how to interpret this. In my case,
              High string set means GBE strings, and Low means DGB strings.
              But if you play common bar chords, High could be the one with the root on the A-String and Low the one with root on the low E-String.
            </li>
            <li><strong>Simple 251</strong> - basic II-V-I progressions in Major and Minor and all keys (<a href="/assets/preview/Simple251JamCGuitarDeck.html" target="_blank">Preview Deck here</a>)</li>
        </ul>
        <p>These are also available for C, Bb and Eb instruments.</p>
        <p>
            Cards are sorted by difficulty, where difficulty includes the frequency chords are used in popular music (most common chord types come first),
            number of different chords in the progression, number of accidentals, tempo of playback and some other criteria.
            So, you probably don't want to configure Anki to show cards in random order (unless you really want to).
        </p>
      </div>

      <div class="jumbotron bg-light mt-3 pt-4 pb-3 mb-0">
        <h2 class="heading">Example</h2>
        <hr>
        <app-jam-carousel [cards]="compingCards" [error]="compingError" titlePrefix="Play"></app-jam-carousel>
      </div>

      <div class="jumbotron bg-light mt-3 pt-4 pb-3 mb-0">
        <h2 class="heading">Description</h2>
        <hr>
        <h4>Fields of Chord deck (jazz chords)</h4>
        <p>
            The <strong>Type</strong> field can have the following values:
        </p>
        <ul>
            <li><strong>Triads</strong>
                <div>Progressions built of Major, Minor, Augmented and Diminished Triads</div>
            </li>
            <li><strong>Workouts</strong>
                <div>Chord progressions that focus on a single chord.
                    Walks through the most commonly used chords in modern music, including e.g. Minor 7, Major 7, Dominant 7, 7sus4, Altered chords and some substitusions.
                    Although the progressions focus on a single chord type, they are arranged in a way that sound at least somewhat musical.
                </div>
            </li>
            <li><strong>Simple II-V-I Progressions</strong>
                <div>II-V-I progressions in Major and Minor with only few chord substitutions</div>
            </li>
            <li><strong>Extended II-V-I Progressions</strong>
                <div>II-V-I progressions in Major and Minor with more chord substitutions</div>
            </li>
            <li><strong>Jazz Blues</strong>
                <div>Jazz Blues progressions in Major and Minor</div>
            </li>
        </ul>
        <h4>Download</h4>
        <p>
            These decks are available for download on <a href="https://ankiweb.net/shared/decks/ankijazz" target="_blank">AnkiWeb</a> site.
            <app-coffee what="them"></app-coffee>
        </p>

    </div>

</div>
