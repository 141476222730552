<div class="container">

  <div>
    <div class="jumbotron bg-light mt-3 pt-4 pb-3 mb-0">
      <h2 class="heading">Contact</h2>
      <hr>
      <p>
        I'm a professional software developer and a hobby musician. AnkiJazz is not a company, it's just my pet project.
        Initially I created the Anki decks for myself, but then I thought they might be useful for others too.
      </p>
      <p>
        Your feedback is very much appreciated. Especially if you find an error or something odd, please let me know via <a href="mailto:andreas@ankijazz.com">andreas@ankijazz.com</a>.
      </p>
      <p>Andreas</p>
    </div>
  </div>

</div>