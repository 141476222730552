<div class="container">
    <div class="jumbotron bg-light mt-3 pt-4 pb-3 mb-0">
        <h2 class="heading">CAGED + 3NPS Guitar Scale Fingerings</h2>
        <hr>
        <h4>Overview</h4>
        <p>
            CAGED is a common fingering system with 5 positions to play scales on Guitar.
            There are five decks with increasing difficulty to learn and practice these:
        </p>
        <ul>
            <li>Level 1 - fretboard diagrams for Major and Minor scales (<a href="/assets/preview/Caged1ScalesFretboards.html" target="_blank">Preview Deck here</a>)</li>
            <li>Level 2 - jam tracks to practice scales (<a href="/assets/preview/Caged2ScalesJamtracks.html" target="_blank">Preview Deck here</a>)</li>
            <li>Level 3 - fretboard diagrams for Major and Minor scales and their modes (<a href="/assets/preview/Caged3ModesFretboards.html" target="_blank">Preview Deck here</a>)</li>
            <li>Level 4 - jam tracks to practice scales and modes (<a href="/assets/preview/Caged4ModesJamtracks.html" target="_blank">Preview Deck here</a>)</li>
            <li>Level 5 - fretboard diagrams for Major and Minor scales, their modes and arpeggios (<a href="/assets/preview/Caged5ArpeggiosFretboards.html" target="_blank">Preview Deck here</a>)</li>
        </ul>
        <p>
            3NPS (3 Notes Per String) is another very popular fingering. There are three decks:
        </p>
        <ul>
            <li>3NPS Scales - fretboard diagrams for Major and Minor scales (<a href="/assets/preview/3NPSScalesFretboards.html" target="_blank">Preview Deck here</a>)</li>
            <li>3NPS Modes - fretboard diagrams for Major and Minor scales and their modes (<a href="/assets/preview/3NPSModesFretboards.html" target="_blank">Preview Deck here</a>)</li>
            <li>3NPS Modes and Arpeggios - fretboard diagrams for Major and Minor scales, their modes and arpeggios (<a href="/assets/preview/3NPSArpeggiosFretboards.html" target="_blank">Preview Deck here</a>)</li>
        </ul>
      </div>

      <div class="jumbotron bg-light mt-3 pt-4 pb-3 mb-0">
        <h2 class="heading">Example</h2>
        <app-fret-carousel [cards]="fretCards" [error]="fretError" titlePrefix="Play"></app-fret-carousel>
      </div>

      <div class="jumbotron bg-light mt-3 pt-4 pb-3 mb-0">
        <h2 class="heading">Description</h2>
        <hr>
        <h4>Melodic Minor and Harmonic Minor</h4>
        <p>
            While CAGED fingerings for the Major scale are pretty much defined, there exist some variations for Melodic and Harmonic Minor.
            I chose the following:
        </p>
        <ul>
            <li>When you play the Dorian mode with a major 7th instead of the minor 7th, you get Melodic Minor.
                So I took the Dorian fingering and raised the 7th degree by one fret.</li>
            <li>When you play the Aeolean mode with a major 7th instead of the minor 7th, you get Harmonic Minor.
                So I took the Aeolean fingering and raised the 7th degree by one fret.</li>
        </ul>
        <p>
            I like this systematic approach because it gives good results and is easy to remember.
            However, in the CAGED system there is one exception to this rule: if the root is on the E-strings, then the fingering becomes easier if you move the major 7th from B-String to E-String too.
        </p>
        <h4>Modes and Chords</h4>
        <p>
            In both card types, fretboard and jamtrack, the audio plays chords.
            This is the chord you get by stacking the thirds of the mode with the following exceptions:
        </p>
        <ul>
            <li>for Phrygian Dominant (5th mode of Harmonic Minor) I chose a 7b9 chord</li>
            <li>for Altered Scale (7th mode of Melodic Minor) I chose a 7#5 chord</li>
        </ul>
        <h4>Download</h4>
        <p>
            These decks are available for download on <a href="https://ankiweb.net/shared/decks/ankijazz" target="_blank">AnkiWeb</a> site.
            <app-coffee what="them"></app-coffee>
        </p>

    </div>

</div>
