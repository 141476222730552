<div class="container">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3">
        <a class="navbar-brand" [routerLink]="'home'">
            <img src="/assets/img/anki-logo32x32.png"> AnkiJazz</a>
        <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>

        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/home" routerLinkActive="active" (click)="collapseMenu()">Home</a>
                </li>
                <li class="nav-item">
                    <a  class="nav-link" routerLink="/scales" routerLinkActive="active" (click)="collapseMenu()">Scales</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/rhythm" routerLinkActive="active" (click)="collapseMenu()">Rhythms</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/comping" routerLinkActive="active" (click)="collapseMenu()">Chords</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/theory" routerLinkActive="active" (click)="collapseMenu()">Theory</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/caged" routerLinkActive="active" (click)="collapseMenu()">CAGED/3NPS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/pentatonics" routerLinkActive="active" (click)="collapseMenu()">Pentas</a>
                </li>
                <!--
                <li class="nav-item">
                    <a class="nav-link" routerLink="/tools" routerLinkActive="active" (click)="collapseMenu()">Tools</a>
                </li>
                -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/contact" routerLinkActive="active" (click)="collapseMenu()">Contact</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.youtube.com/@fretboardanimation" target="_blank" (click)="collapseMenu()">YT</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://apps.ankiweb.net/" target="_blank" (click)="collapseMenu()">Anki</a>
                </li>
            </ul>
        </div>
    </nav>
</div>
