<div class="">
    <!-- jumbotron bg-light mt-3 pt-4 pb-3 mb-0 -->
    <h3 class="heading">SMPTE, Tempo and Duration Calculator</h3>
    <p>
        Whether scoring music for a video or applying sound design effects,
        the duration of your clip dictates the duration of your music.
        This tool offers various approaches to make your music fit perfectly.
        You can now hit those sync points exactly!
    </p>
    <div class="m-4">
        <form (ngSubmit)="calculate()" #heroForm="ngForm">

            <div class="pt-4 pl-4 pb-0">
                <div class="form-group row">
                    <label for="framesPerSecond" class="col-md-3 col-form-label">Frames per Second</label>
                    <div class="col-md-9">
                        <input type="number" min="1" max="99" size="2" [(ngModel)]="framesPerSecond"
                            name="framesPerSecond" id="framesPerSecond">
                    </div>
                </div>
            </div>

            <div class="bg-light border border-primary pt-4 pl-4 pb-0">
                <div class="row">
                    <p class="col">
                        The <b>SMPTE</b> code (HH:MM:SS:FF) represents an exact point in time:
                    </p>
                </div>

                <div class="form-group row">
                    <label for="startHours" class="col-md-3 col-form-label">In-Point</label>
                    <div class="col-md-9">
                        <input type="number" min="0" max="99" size="2" [(ngModel)]="start.hours" name="startHours"
                            id="startHours"> :
                        <input type="number" min="0" max="59" size="2" [(ngModel)]="start.minutes" name="startMinutes"
                            id="startMinutes"> :
                        <input type="number" min="0" max="59" size="2" [(ngModel)]="start.seconds" name="startSeconds"
                            id="startSeconds"> :
                        <input type="number" min="0" [max]="framesPerSecond - 1" size="2" [(ngModel)]="start.frames"
                            name="startFrames" id="startFrames">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="endHours" class="col-md-3 col-form-label">Out-Point</label>
                    <div class="col-md-9">
                        <input type="number" min="0" max="99" size="2" [(ngModel)]="end.hours" name="endHours"
                            id="endHours"> :
                        <input type="number" min="0" max="59" size="2" [(ngModel)]="end.minutes" name="endMinutes"
                            id="endMinutes"> :
                        <input type="number" min="0" max="59" size="2" [(ngModel)]="end.seconds" name="endSeconds"
                            id="endSeconds"> :
                        <input type="number" min="0" [max]="framesPerSecond - 1" size="2" [(ngModel)]="end.frames"
                            name="endFrames" id="endFrames">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="lengthHours" class="col-md-3 col-form-label">Clip Duration</label>
                    <div class="col-md-9">
                        <input class="text-secondary" type="number" min="0" max="99" size="2" readonly
                            value="{{length.hours | number: '2.0-0'}}" name="lengthHours" id="lengthHours"> :
                        <input class="text-secondary" type="number" min="0" max="99" size="2" readonly
                            value="{{length.minutes | number: '2.0-0'}}"> :
                        <input class="text-secondary" type="number" min="0" max="99" size="2" readonly
                            value="{{length.seconds | number: '2.0-0'}}"> :
                        <input class="text-secondary" type="number" min="0" max="99" size="2" readonly
                            value="{{length.frames | number: '2.0-0'}}">
                    </div>
                </div>
            </div>


            <div class="pt-4 pl-4 pb-0">
                <div class="form-group row">
                    <label for="beatsPerBar" class="col-md-3 col-form-label">Beats per Bar</label>
                    <div class="col-md-9">
                        <input type="number" min="1" max="99" size="2" [(ngModel)]="beatsPerBar" name="beatsPerBar"
                            id="beatsPerBar">
                    </div>
                </div>
            </div>

            <div class="">
                <div class="row ">
                    <div class="col-lg-6 ">
                        <div class="bg-light border border-primary p-3">
                            <h5>Scenario 1</h5>
                            <div class="form-group row">
                                <label for="fixedBars" class="col-md-4 col-form-label">Desired length</label>
                                <div class="col-md-8">
                                    <input type="number" min="0" max="9999" size="4" [(ngModel)]="fixedBars"
                                        name="fixedBars" id="fixedBars">
                                    <span> bars plus </span>
                                    <input type="number" min="0" [max]="beatsPerBar - 1" size="5"
                                        [(ngModel)]="fixedBeats" name="fixedBeats" id="fixedBeats">
                                    <span> beats </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-md-4">
                                    <span>Tempo to use</span>
                                </div>
                                <div class="col-md-8">
                                    <span><span class="text-primary">{{computedBpm | number: '1.3-3'}}</span> bpm</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 ">
                        <div class="bg-light border border-primary p-3">
                            <h5>Scenario 2</h5>
                            <div class="form-group row">
                                <label for="fixedBpm" class="col-md-4 col-form-label">Desired tempo</label>
                                <div class="col-md-8">
                                    <input type="number" min="1" max="999" size="7" [(ngModel)]="fixedBpm"
                                        name="fixedBpm" id="fixedBpm"> <span> bpm</span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-md-4">
                                    <span>Bars to create</span>
                                </div>
                                <div class="col-md-8">
                                    <span><span class="text-primary">{{computedBars | number: '1.0-0'}}</span> bars plus
                                        <span class="text-primary">{{computedBeats | number: '1.3-3'}}</span>
                                        beats</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <p class="mt-4">
                <button type="submit" class="btn btn-primary">Calculate</button>
            </p>
        </form>

    </div>

</div>