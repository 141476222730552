<div>
    <div *ngIf="error" class="alert alert-danger" role="alert">
        Could not load data: {{error}}.
    </div>
    <div class="d-flex justify-content-center">
        <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="showNavigationArrows"
            [interval]="interval" *ngIf="cards" class="maxw45">
            <ng-template ngbSlide *ngFor="let card of cards">
                <div class="card m-3">
                    <h4 class="card-header">
                        {{titlePrefix}} {{card.title}} in {{card.key}} in {{card.positionLabel}} Position
                    </h4>
                    <div class="card-body">
                        <p class="text-center"><img src="/assets/preview/{{card.positionImage}}"></p>
                        <p><img class="card-img-top" src="/assets/preview/{{card.songPng}}"></p>
                        <p class="card-text text-center">
                            {{card.comment}}
                        </p>
                        <p class="card-text text-center">
                            <audio controls src="/assets/preview/{{card.songMp3}}" (play)="onPlayerPlay()"
                                (pause)="onPlayerPause()" (ended)="onPlayerEnded()"></audio>
                        </p>
                        <p class="card-text">Fields available for sorting and filtering:</p>
                        <ul>
                            <li>Title: {{card.title}}</li>
                            <li>Comment: {{card.comment}}</li>
                            <li>Type: {{card.type}}</li>
                            <li>Key: {{card.key}}</li>
                            <li>Style: {{card.style}}</li>
                            <li>Tempo: {{card.tempo}}</li>
                        </ul>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>
